import styled from "styled-components";
import { OutboundLink } from "react-ga";

export const Container = styled.main`
  display: flex;
  flex-direction: column;
  min-height: 100vh;
  position: relative;
`;

export const Wrapper = styled.div`
  background-color: #fefefd;
  min-height: 100%;
  flex: 1 0 auto;
  padding: 1rem 2rem;

  @media (min-width: 700px) {
    padding: 3rem 5rem;
  }
`;

export const Text = styled.span`
  color: ${({ color }) => (color === "green" ? "#35D0BA" : "#8E8E8E")};
  font-family: Noto Sans SC;
  font-weight: ${({ bold }) => (bold ? "500" : "300")};
  line-height: 150%;
  font-size: 1rem;
  height: 100%;

  @media (min-width: 700px) {
    line-height: 2.8rem;
    font-size: 2rem;
  }
`;

export const Footer = styled.footer`
  display: flex;
  justify-content: center;
  flex-shrink: 0;
  padding-bottom: 1rem;
`;

export const FooterText = styled.span`
  font-weight: 300;
  font-family: Noto Sans SC;
  font-size: 0.8rem;
  color: #8e8e8e;
`;

export const Link = styled(OutboundLink)`
  font-weight: 300;
  color: #35d0ba;
  font-family: Noto Sans SC;
  font-size: 0.8rem;
  cursor: pointer;
  text-decoration: none;
  text-align: center;
`;
