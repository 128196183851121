import React, { Fragment } from "react";
import { render } from "react-dom";
import { createGlobalStyle } from "styled-components";
import reset from "styled-reset";
import ReactGA from "react-ga";

ReactGA.initialize("UA-130559835-1", {
  debug: false
});
ReactGA.pageview(window.location.pathname + window.location.search);

import App from "./components/App";

const GlobalStyle = createGlobalStyle`
  ${reset}
`;

const Application = () => (
  <Fragment>
    <GlobalStyle />
    <App />
  </Fragment>
);

render(<Application />, document.getElementById("root"));
