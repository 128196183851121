import React, { Fragment } from "react";
import { BrowserRouter as Router, Route } from "react-router-dom";

import { Container, Text, Wrapper, Link, Footer, FooterText } from "./style";

const getName = pathname => {
  const name = pathname
    .split("/")[1]
    .toLowerCase()
    .trim();
  return name === ""
    ? "Technology fiends"
    : name.charAt(0).toUpperCase() + name.slice(1);
};

const Page = props => (
  <Container>
    <Wrapper>
      <Text>
        <Text color="green" bold>
          {getName(props.location.pathname)}
          {", "}
        </Text>
        we get it. You're painfully woke. Everything you eat is free of
        everything, ever. You know which products contain ingredients from
        war-zones you can't pronounce. You know which of your distant family
        friends might have maybe potentially voted Leave and you haven't talked
        to them since. You'd unfriend them on Facebook too but you're not on
        Facebook anymore, because obviously. You went on that march for that
        thing and made a funny yet socially cutting placard that got over 100
        likes on Instagram... I mean that helped raise awareness of a painfully
        prescient issue. Your clothes are fourth hand. Your vegetables are
        foraged. You have seven different types of rubbish bin. Well, eight, but
        you melted one down and turned it into a reusable coffee cup. You have a
        subscription to a company that delivers cold-pressed rapeseed oil direct
        to your door and pours a month's worth right into your cupped hands
        because packaging makes you WRETCH. You've developed - over many years -
        a keen awareness of all the biases and externalities that cause society
        to work sub-optimally. So isn't it time you dedicated some of that
        energy into developing an equally keen awareness of yourself?
        <Text color="green" bold>
          {" "}
          Isn't it time you became emotionally woke?
        </Text>
      </Text>
    </Wrapper>
    <Footer>
      <FooterText>
        {"powered by "}
        <Link
          eventLabel="Spill Site Link"
          target="_blank"
          to="https://www.spill.chat/"
        >
          Spill
        </Link>
      </FooterText>
    </Footer>
  </Container>
);

const App = () => (
  <Router>
    <Fragment>
      <Route path="/*" component={props => <Page {...props} />} />
    </Fragment>
  </Router>
);

export default App;
